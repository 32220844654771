<template>
    <div class="icp">{{`© ${year} ${author} ` }} <a href="http://beian.miit.gov.cn/" target="_blank">{{ record }}</a></div>
</template>

<script setup>
let year = new Date().getFullYear();        // 一般都是最新的一年
let author = '厦门惠天建技人才服务有限公司';					// 作者名
let record = '  闽ICP备2024078631号';			// 备案号
</script>

<style>
.icp {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
    padding: 5px 0; /* 可以根据需要调整内边距 */
    font-size: 11px; /* 调整字体大小，可以根据需要修改 */
	/* height: 36px; */
	white-space: pre;
	text-align: center;
	color: gray;
	z-index: 1000;
}
.icp > a {
    color: gray;
    text-decoration: none;
}
.icp > a:hover {
    color: aqua;
    text-decoration: none;
}
</style>
